<template>
    <div class="list-info">
        <top-bar :title="'避孕药具发放'" :left="true"></top-bar>
        <div class="search">
            <input v-model="searchValue" type="search" value="搜索" placeholder="请输入领取人姓名" class="search-content" @keyup.enter="onSearch">
            <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
            <div class="search-btn" @click="onSearch">搜索</div>
        </div>
        <div class="filterBar">
            <van-row>
                <van-col span="12">
                    <p @click="dateBarShow = !dateBarShow" :style="{color:dateBarShow?'#387FF5':'#666666'}">发放时间
                        <img :src="require(`@/assets/img/${dateBarShow?'pull-down-select':'pull-down'}.png`)" alt=""
                             class="pull-icon">
                    </p>
                </van-col>
                <van-col span="12">
                    <p @click="subShow = !subShow" :style="{color:subShow?'#387FF5':'#666666'}">
                        {{ subName }}
                        <img :src="require(`@/assets/img/${subShow?'pull-down-select':'pull-down'}.png`)" alt=""
                             class="pull-icon">
                    </p>
                </van-col>
            </van-row>
        </div>
        <van-popup v-model="subShow" position="bottom">
            <van-picker cancel-button-text="重置" title="请选择" show-toolbar :columns="subList" value-key="name" @confirm="gridConfirm"
                        @cancel="reset"/>
        </van-popup>
        <div class="dateBar" v-show="dateBarShow">
            <van-row>
                <van-col span="8" @click="beginDateShow = !beginDateShow">
                    <span>{{ selectDate.startTime === '' ? '最早' : selectDate.startTime }}</span></van-col>
                <van-col span="2"><span>-</span></van-col>
                <van-col span="8" @click="endDateShow = !endDateShow">
                    <span>{{ selectDate.endTime === '' ? '至今' : selectDate.endTime }}</span></van-col>
                <van-col span="3" @click="dateClose"><span>重置</span></van-col>
                <van-col span="3" @click="changeDate"><span>确定</span></van-col>
            </van-row>
        </div>
        <van-popup v-model="beginDateShow" position="bottom">
            <van-datetime-picker @confirm="beginDateConfim" @cancel="beginDateShow = false" :formatter="formatDate"
                                 type="date" :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
        </van-popup>
        <van-popup v-model="endDateShow" position="bottom">
            <van-datetime-picker @confirm="endDateConfim" @cancel="endDateShow = false" :formatter="formatDate"
                                 type="date" :min-date="new Date(2017, 0, 1)" :max-date="new Date(2025, 10, 1)"/>
        </van-popup>
        <div class="addBtn" @click="goAdd">
            <img src="@/assets/img/add.png" alt="">
        </div>
        <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
            <div class="cont">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"
                          v-if="dataList.length > 0" offset="10">
                    <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{ totalCount }}</span>&nbsp;条数据</div>
                    <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id)">
                        <div class="title">
                            <div class="title-left">
                                {{ item.name + ' | ' + item.acyeterionTime }}
                            </div>
                            <div class="title-right">
                                {{ item.mobile }}
                            </div>
                        </div>
                        <div class="content">
                            <van-image :src="imgUrl(item.url)" class="content-img">
                                <template slot="error">
                                    <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                                </template>
                                <template slot="loading">
                                    <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                                </template>
                            </van-image>
                            <div class="content-text">
                                <div class="top-text">{{ item.subareaStr }}</div>
                                <div class="bottom-text">{{ item.des }}</div>
                            </div>
                            <van-icon name="arrow" color="#666" class="content-icon"/>
                        </div>
                    </div>
                </van-list>
                <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
            </div>
        <!-- </van-pull-refresh> -->
    </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {formatterDate} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import {mapMutations} from 'vuex'

export default {
    name: 'visit',
    components: {
        topBar
    },
    data() {
        return {
            subName: '所属小区',
            loading: false,
            finished: false,
            endDateShow: false,
            beginDateShow: false,
            dateBarShow: false,
            subShow: false,
            page: 0,
            limit: 10,
            searchValue: '',
            selectDate: {
                startTime: '',
                endTime: ''
            },
            pullLoading: false,
            subarea: '',
            subList: [],
            totalCount: 0,
            dataList: []
        };
    },
    methods: {
        ...mapMutations,
        imgUrl(img) {
            if (img != null) {
                return getImageStream(img)
            } else {
                return require('@/assets/img/flower.png')
            }
        },
        getInfo(id) {
            this.$router.push({path: '/acyeterionList-add', query: {id: id}})
        },
        getDataList() {
            this.page++
            this.$toast.loading({duration: 0, message: '加载中...', forbidClick: true,});
            this.$http({
                url: this.$http.adornUrl('/wxapp/acyeterion/list'),
                method: 'post',
                params: this.$http.adornParams({
                    // orgId: this.$orgId,
                    page: this.page,
                    limit: this.limit,
                    searchValue: this.searchValue,
                    startTime: this.selectDate.startTime,
                    endTime: this.selectDate.endTime,
                    subarea: this.subarea
                })
            }).then(({data}) => {
                this.pullLoading = false
                if (data.code === 0) {
                    this.totalCount = data.page.totalCount
                    if (this.dataList.length === data.page.totalCount) {
                        this.finished = true
                    }
                    this.dataList = this.dataList.concat(data.page.list)
                    // 加载状态结束
                    this.loading = false;
                    this.$toast.clear()
                }
                this.$toast.clear()
            }, err => {
                this.$toast.clear()
            })
        },
        getSubList() {
            this.$http({
                url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
                method: 'post',
                params: this.$http.adornParams({
                    communityId: this.$orgId
                })
            }).then(({data}) => {
                if (data.code === 0) {
                    this.subList = data.subAreaList
                } else {
                    this.$toast.fail(data.msg);
                }
            })
        },
        /**
         * 页面相关事件处理函数--用户下拉动作
         */
        onPullDownRefresh(e) {
            this.finished = false
            this.dataList = []
            this.page = 0;
            this.getDataList()
        },
        formatDate(type, val) {
            if (type === 'year') {
                return val + '年'
            }
            if (type === 'month') {
                return val + '月'
            }
            if (type === 'day') {
                return val + '日'
            }
            return val
        },
        onSearch(val) {
            this.page = 0
            this.dataList = []
            this.finished = false
            this.getDataList()
        },
        onCancel() {
            this.page = 0
            this.dataList = []
            this.finished = false
            this.searchValue = ''
            this.getDataList()
        },
        beginDateConfim(value) {
            let d = new Date(this.selectDate.endTime)
            if (this.selectDate.endTime != '' && d.getTime(d) < value.getTime(value)) {
                return this.$toast.fail('开始时间不能比结束时间大哦')
            }
            this.selectDate.startTime = formatterDate(value)
            this.beginDateShow = false
        },
        endDateConfim(value) {
            let d = new Date(this.selectDate.startTime)
            if (this.selectDate.startTime != '' && d.getTime(d) > value.getTime(value)) {
                return this.$toast.fail('结束时间不能比开始时间小哦')
            }
            this.selectDate.endTime = formatterDate(value)
            this.endDateShow = false
        },
        changeDate() {
            this.dataList = []
            this.page = 0
            this.finished = false
            this.getDataList()
        },
        dateClose() {
            this.selectDate = {
                endTime: '',
                startTime: ''
            }
        },
        gridConfirm(value, index) {
            this.page = 0
            this.dataList = []
            this.subName = value.name
            this.subarea = value.id
            this.finished = false
            this.getDataList()
            this.subShow = false
        },
        reset(){
            this.finished = false
            this.subShow = false
            this.page = 0
            this.subarea = ''
            this.dataList = []
            this.getDataList()
        },
        goAdd() {
            this.$router.push({path: '/acyeterionList-add', meta: {keepAlive: true}})
        }
    },
    mounted() {
        console.log(1)
        this.$toast.loading({
            duration: 0,
            message: '加载中...',
            forbidClick: true,
        });
        this.userId = this.$globalData.userInfo.userId
        this.getDataList()
        this.getSubList()
    }
}
</script>
